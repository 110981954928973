<template>
  <b-card-code
    title=""
  >
    <CustomerListComponent
      root="customers"
    />
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import CustomerListComponent from './components/CustomerListComponent.vue'

export default {
  name: 'CustomerPage',
  components: {
    BCardCode,
    CustomerListComponent,
  },
}
</script>
